import { Link } from 'react-router-dom';
import {ReactComponent as Logo} from './logo.svg';
import Wrapper from './Wrapper';

type Props = {
  isFrontPage: boolean;
  isInflationPage?: boolean;
  isPriceTrackerPage?: boolean;
}

function Navbar(props: Props) {
  const linkStyle = "mt-[-2px] text-xl font-semibold mr-6 hover:underline underline-offset-4 decoration-2 decoration-zinc-600 hover:text-zinc-600 ";
  return (
    <Wrapper className="bg-cultured h-24 flex items-center">
      <div className="flex">
        <Link to="/" className="inline-block"><Logo/></Link>
        <div className="ml-auto self-center text-zinc-400">
          <Link to="/" className={linkStyle + (props.isFrontPage ? " text-zinc-600 underline " : "")}>Home</Link>
          <a href="/blog" className={linkStyle}>Blog</a>
          <Link to="/inflation" className={linkStyle + (props.isInflationPage ? " text-zinc-600 underline " : "")}>Inflation</Link>
          <Link to="/holiday-price-tracker" className={linkStyle + (props.isPriceTrackerPage ? " text-zinc-600 underline " : "")}>Price Tracker</Link>
        </div>
        {/* <Button primary={false} secondary={true} className=" mt-[-2px] ml-auto self-center ">Blog</Button> */}
      </div>
    </Wrapper>
  );
}

export default Navbar;