import React, { useEffect, useState } from 'react';

import Navbar from './Navbar';
import Hero from './Hero';
import FeaturedDeal from './FeaturedDeal';
import Footer from './Footer';
import Wrapper from './Wrapper';
import SearchBox, { genSearchParams, SearchSettings } from './SearchBox';
import ResultsList, { SearchResponse } from './ResultsList';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleInfo, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import API_URI from './utils';
import {Helmet} from "react-helmet";
import Chart from "react-apexcharts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, ComposedChart, ReferenceLine, Label } from 'recharts';
import { useSearchParams } from 'react-router-dom';
import SearchResultItem from './SearchResultItem';
import { getHotelTitle } from './utils';
import PeopleCount from './SearchBox/PeopleCount';
import Select, { SingleValue } from 'react-select'

type InflationPoint = {
  date: string;
  inflation: number;
};

type InflationResponse = {
  series: InflationPoint[];
};

type Props = {
};

const percFormatter = (value: number | string): string => {
  if (typeof value === 'string') {
    return percFormatter(parseFloat(value));
  } else {
    const intl = new Intl.NumberFormat('en').format(value);
    return intl + "%";
  }
};

function InflationPage(props: Props) {

  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [response, setResponse] = useState<InflationResponse | null>(null);

  useEffect(
    () => {
      fetchInflationData();
    },
    []
  )


  function fetchInflationData() {
    // After 200ms of waiting, show spinner. Otherwise update "instaneously" for nicer UX.
    const timeoutID = setTimeout(() => setIsLoaded(false), 200);

    fetch(API_URI + "/inflation")
      .then(res => res.json())
      .then(
        (response) => {
          clearTimeout(timeoutID);
          setIsLoaded(true);
          setResponse(response);
        },
        (error) => {
          clearTimeout(timeoutID);
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  let content = null;

  if (error) {
    content = (
      <>
        <div className="flex justify-center m-8">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-9xl text-violet" />
        </div>
        <h1 className="text-xl text-center">Our mice had some trouble</h1>
        <p className="text-sm text-center">The error we experienced is '{error.message}'. This may be a temporary problem, retry later or change your search settings.</p>
      </>
    );
  } else if (!isLoaded) {
    content = (<Spinner />);
  } else if (response == null || response.series.length == 0) {
    content = (
      <>
        <div className="flex justify-center m-8">
          <FontAwesomeIcon icon={faCircleExclamation} className="text-9xl text-shamrock" />
        </div>
        <h1 className="text-xl text-center">Our mice weren't able to find any results this time</h1>
        <p className="text-sm text-center">Try again later.</p>
      </>
    )
  } else {

    content = (
      <div className="h-[960px] ">
        <ResponsiveContainer>
          <ComposedChart
            width={1280}
            height={960}
            data={response.series}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date">
              <Label
                    style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        marginRight: "2pt"
                    }}
                  dy={20}
                  value={"Date"} />
            </XAxis>
            <YAxis tickFormatter={percFormatter}>
              <Label
                  style={{
                      textAnchor: "middle",
                      fontSize: "130%",
                      marginRight: "2pt"
                  }}
                dx={-20}
                angle={270}
                value={"Inflation (%)"} />
            </YAxis>
            <Line type="monotone" dataKey="inflation" stroke="#f29422" dot={false} strokeWidth={2} />
            {/* @ts-ignore */}
            <Tooltip formatter={percFormatter} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return (
    <>
      <Navbar isFrontPage={false} isInflationPage={true} />
      <Helmet>
          <title>{"Walt Disney World Holiday Inflation Tracker - Mousetrack.co.uk"}</title>
      </Helmet>
      <Wrapper className="border-grey-200 border-y-2 py-4">
        <h1 className="text-3xl my-8">Walt Disney World Holiday Inflation Tracker</h1>
        <p className="mb-4">
          The chart below shows the yearly percentage change in Walt Disney
          World holiday prices. The inflation data incorporates many factors,
          including hotel, ticket and flight prices. It also includes both
          value hotels as well as moderate and deluxe hotels.
        </p>
        <p className="mb-4">
          The data is still a work in progress. Changes may be made to the
          algorithm. <a href="mailto:inflation@mousetrack.co.uk"
             className='text-slate-600 border-b-2 border-slate-800 hover:border-0'>
          Get in touch</a> if you have any feedback.
        </p>
      </Wrapper>
      <Wrapper>
        <section className="pt-4">
          {content}
        </section>
        <Footer />
      </Wrapper>
    </>
  );
}

export default InflationPage;