
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from './Button';
import PeopleCount from './SearchBox/PeopleCount';
import { useEffect, useState } from 'react';
import CustomCalendar, { getStartDate } from './SearchBox/CustomCalendar';
import { faClock, faInfoCircle, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBill1 as farMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import OriginSelector from './SearchBox/OriginSelector';
import TypeSelector from './SearchBox/TypeSelector';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { getHotelTitle, getShortHotelTitle, parseDate, renderPrice, renderPriceShort } from './utils';
import { genHistoryParams } from './HistoryPage';
import humanizeDuration from 'humanize-duration';

type Props = {
  hotel: string,
  min: number[],
  max: number[],
  lastUpdated: number,
  myKey: number,
  myRef: React.MutableRefObject<null | HTMLDivElement>,
};

function getPriceGraphUrl(props: Props): string {
  let query = genHistoryParams({
    hotel: props.hotel,
    adults: 2,
    children: 0,
    duration: 14,
  }).toString();

  return "/history/hotel?" + query;
}

function PriceCarouselItem(props: Props) {
  const duration = humanizeDuration(
    (new Date()).getTime() - props.lastUpdated*1000,
    {
      units: ["d", "h", "m"],
      largest: 1,
      round: true
    }
  );

  // const table = (
  //   <table className="hidden md:table border-collapse w-full">
  //     <thead >
  //       <tr>
  //         <th></th>
  //         <th className="bg-cultured rounded-t-lg font-normal border-cultured-dark border-b-2 text-sm lg:text-normal" colSpan={3}>Nights</th>
  //       </tr>
  //       <tr className="text-xs lg:text-sm">
  //         <th></th>
  //         <th className="font-normal bg-cultured border-cultured-dark">1</th>
  //         <th className="font-normal bg-cultured border-cultured-dark">7</th>
  //         <th className="font-normal bg-cultured border-cultured-dark">14</th>
  //       </tr>
  //     </thead>
  //     <tbody className="text-center text-xs lg:text-sm">
  //       <tr>
  //         <td className="bg-cultured rounded-tl-lg text-right px-2 py-1 w-20">Cheapest</td>
  //         <td>{renderPriceShort(props.min[0])}</td>
  //         <td>{renderPriceShort(props.min[1])}</td>
  //         <td>{renderPriceShort(props.min[2])}</td>
  //       </tr>
  //       <tr>
  //         <td className="bg-cultured rounded-bl-lg text-right px-2 py-1">Dearest</td>
  //         <td>{renderPriceShort(props.max[0])}</td>
  //         <td>{renderPriceShort(props.max[1])}</td>
  //         <td>{renderPriceShort(props.max[2])}</td>
  //       </tr>
  //     </tbody>
  //   </table>
  // );

  let middleDot = (
    <span className="px-2">·</span>
  );
  const shortSummary = (
    <div className="text-xs md:text-sm ml-2">
      <div className="font-light mt-1 whitespace-nowrap">
        <div className="bg-cultured-dark px-2 py-0.5 mr-2 inline rounded-xl">
          <FontAwesomeIcon icon={farMoneyBill1} className="pr-2" />1 Night
        </div>
        Min: {renderPriceShort(props.min[0])}
        {middleDot}
        Max: {renderPriceShort(props.max[0])}
      </div>
      <div className="font-light mt-2 whitespace-nowrap">
        <div className="bg-cultured-dark px-2 py-0.5 mr-2 inline rounded-xl">
          <FontAwesomeIcon icon={farMoneyBill1} className="pr-2" />7 Nights
        </div>
        Min: {renderPriceShort(props.min[1])}
        {middleDot}
        Max: {renderPriceShort(props.max[1])}
      </div>
      <div className="font-light mt-2 whitespace-nowrap">
        <div className="bg-cultured-dark px-2 py-0.5 mr-2 inline rounded-xl">
          <FontAwesomeIcon icon={farMoneyBill1} className="pr-2" />14 Nights
        </div>
        Min: {renderPriceShort(props.min[2])}
        {middleDot}
        Max: {renderPriceShort(props.max[2])}
      </div>
    </div>
  )

  return (
    <div className={"bg-white p-4 rounded-lg border-cultured-dark border border-1 mr-4 flex flex-col"} id={"explore-" + props.myKey} key={props.myKey} ref={props.myRef}>
      <div className="flex max-h-36 md:max-h-48 lg:max-h-60 min-w-[24.5rem] md:min-w-[28rem]">
        <div className="w-full">
          <h3 className="lg:text-xl font-semibold whitespace-nowrap">{getShortHotelTitle(props.hotel)}</h3> { /* whitespace-nowrap and make the div expand properly to different title sizes */}
          {/* <p className="text-shamrock-dark text-xs hidden md:block">
            <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />
            The numbers below reflect live prices of this hotel.
          </p>
          <p className="text-shamrock-dark text-xs mb-2  hidden md:block">
            <FontAwesomeIcon icon={faClock} className="pr-2" />
            Last updated {duration} ago.
          </p> */}
          {shortSummary}
          {/* {table} */}

        </div>
        <img src={"images/" + props.hotel + ".jpg"} className="rounded-md max-h-20 inline ml-4" alt={getHotelTitle(props.hotel)} />
      </div>
      <div className="w-full">
        <p className="text-gray-400 text-xs hidden md:block mt-2 ml-2">
          <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />
          The numbers above reflect live prices of the {getShortHotelTitle(props.hotel)} hotel.
        </p>
      </div>
      <div className="w-full mt-auto">
        <div className="flex  w-full mt-2">
          <Button primary={true} size="text-sm" className="text-center m-auto" href={getPriceGraphUrl(props)}>
            <FontAwesomeIcon icon={faMagnifyingGlassChart} className="pr-2" />
            View Price Graph
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PriceCarouselItem;