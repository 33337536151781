
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from './Button';
import PeopleCount from './SearchBox/PeopleCount';
import { useEffect, useState } from 'react';
import CustomCalendar, { getStartDate } from './SearchBox/CustomCalendar';
import { faArrowRightArrowLeft, faHotel, faPlane, faTicket } from '@fortawesome/free-solid-svg-icons';
import OriginSelector from './SearchBox/OriginSelector';
import TypeSelector from './SearchBox/TypeSelector';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { parseDate } from './utils';

export type SearchSettings = {
  flights: boolean;
  tickets: boolean;
  hotels: boolean;
  adults: number;
  children: number;
  origin: string | null;
  dest: string | null; // Always orlando (for now?).
  start: Date;
  duration: number; // Number of days.
  highlight?: string | null; // Optional hotel name to highlight.
};

type Props = {
  isBoxed: boolean; // XXX: We assume when this is false that we are on the /search page.
  onChange?: (newSettings: SearchSettings) => void;
};

const DEFAULT_ORIGIN = "AnyLondon";
const DEFAULT_DEST = "Orlando";

export function genSearchParams(settings: SearchSettings): URLSearchParams {
  let startDate = settings.start;
  let params: any = {
    flights: settings.flights.toString(),
    tickets: settings.tickets.toString(),
    hotels: settings.hotels.toString(),
    adults: settings.adults.toString(),
    children: settings.children.toString(),
    origin: settings.origin ?? DEFAULT_ORIGIN,
    dest: settings.dest ?? DEFAULT_DEST,
    start_date: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`,
    duration: settings.duration.toString(),
  };
  if (settings.highlight) {
    params.highlight = settings.highlight;
  }
  return new URLSearchParams(params);
}

function searchOnClick(settings: SearchSettings, navigate: NavigateFunction, setOriginMissingError: React.Dispatch<React.SetStateAction<boolean>>) {
  if (settings.origin == null) {
    setOriginMissingError(true);
    return;
  }

  let query = genSearchParams(settings).toString();

  navigate("/search?" + query);
}

function SearchBox({ isBoxed, onChange }: Props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [originMissingError, setOriginMissingError] = useState(false);

  let start = searchParams.get("start_date");
  let defaultStartDate = getStartDate();
  const defaultSettings = {
    flights: true,
    tickets: true,
    hotels: true,
    adults: 2,
    children: 0,
    origin: null,
    dest: DEFAULT_DEST,
    start: defaultStartDate,
    duration: 14,
    highlight: null,
  };
  let settingsFromParams = {
    flights: searchParams.get("flights") === "true",
    tickets: searchParams.get("tickets") === "true",
    hotels: searchParams.get("hotels") === "true",
    adults: Number(searchParams.get("adults")),
    children: Number(searchParams.get("children")),
    origin: searchParams.get("origin") ?? null,
    dest: searchParams.get("dest") ?? null,
    start: start ? parseDate(start) : defaultStartDate,
    duration: Number(searchParams.get("duration")),
    highlight: searchParams.get("highlight"),
  };
  // Detect when params aren't set properly.
  if (settingsFromParams.duration == 0 || settingsFromParams.adults == 0) {
    settingsFromParams = defaultSettings;
  }
  let settings: SearchSettings | null = !isBoxed ? settingsFromParams : null;

  const [settingsState, setSettingsState] = useState<SearchSettings>(
    settings ?? defaultSettings
  );
  // https://stackoverflow.com/a/56247483/492186
  // Set up callback whenever the settings state changes to update URL in address bar.
  useEffect(
    () => {
      if (!isBoxed) {
        setSearchParams(genSearchParams(settingsState));
        if (onChange) {
          onChange(settingsState);
        }
      }
      if (originMissingError && settingsState.origin != null) {
        setOriginMissingError(false);
      }
    },
    [settingsState]
  )

  let navigate = useNavigate();
  const searchBtn = (
    <div className="flex justify-center w-full ">
      <Button primary={true} className="absolute mt-[-2px]" onClick={() => searchOnClick(settingsState, navigate, setOriginMissingError)}>Search</Button>
    </div>
  );

  return (
    <div className={"bg-white p-4 max-w-3xl mx-auto text-md lg:text-lg min-w-[21rem] " + (isBoxed ? "rounded-lg shadow-lg" : "")}>
      <TypeSelector
        defaultFlights={settingsState?.flights}
        defaultTickets={settingsState?.tickets}
        defaultHotels={settingsState?.hotels}
        onUpdate={(flights, tickets, hotels) => {
          setSettingsState(prevState => ({ ...prevState, flights: flights, tickets: tickets, hotels: hotels, highlight: null, }));
        }}
      />

      <PeopleCount
        currAdults={settingsState.adults} currChildren={settingsState.children}
        onUpdate={
          (adults: number, children: number) => {
            setSettingsState(
              prevState => ({ ...prevState, adults: adults, children: children, highlight: null,  })
            );
          }
        }
      />

      <div className="my-4 grid sm:grid-cols-[1fr_auto] gap-2">
        <div className="grid grid-cols-[1fr_auto] items-center">
          <OriginSelector
            defaultSelection={!isBoxed ? settingsState.origin : null}
            onChange={
              (newValue) => {
                setSettingsState(
                  prevState => ({ ...prevState, origin: newValue?.value ?? DEFAULT_ORIGIN, highlight: null,  })
                );
              }}
            highlightError={originMissingError}
          />
          <div>
            <FontAwesomeIcon icon={faArrowRightArrowLeft} className="px-2" />
            <span className="pt-[-3px]">Orlando</span>
          </div>
        </div>
        <CustomCalendar
          defaultDuration={!isBoxed ? settingsState.duration : null}
          defaultStartDate={!isBoxed ? settingsState.start : null}
          onChange={
            (newStartDate, newDuration) => {
              setSettingsState(
                prevState => ({ ...prevState, start: newStartDate, duration: newDuration, highlight: null,  })
              );
            }
          } />
      </div>

      {isBoxed ? searchBtn : null}
    </div>
  );
}

export default SearchBox;