
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from './Button';
import PeopleCount from './SearchBox/PeopleCount';
import { createRef, useEffect, useRef, useState } from 'react';
import CustomCalendar, { getStartDate } from './SearchBox/CustomCalendar';
import { faArrowLeft, faArrowRight, faArrowRightArrowLeft, faExclamationTriangle, faHotel, faPlane, faTicket } from '@fortawesome/free-solid-svg-icons';
import OriginSelector from './SearchBox/OriginSelector';
import TypeSelector from './SearchBox/TypeSelector';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import API_URI, { parseDate } from './utils';
import PriceCarouselItem from './PriceCarouselItem';
import Spinner from './Spinner';

type HotelExplorePrices = {
  hotel: string;
  min: number[];
  max: number[];
  lastUpdated: number;
};

type ExploreResponse = {
  hotels: HotelExplorePrices[];
};

type Props = {
};

function PriceCarousel(props: Props) {
  const [response, setResponse] = useState<ExploreResponse | null>();
  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    fetch(API_URI + "/explore")
      .then(res => res.json())
      .then(
        (resp) => {
          setIsLoaded(true);
          setResponse(resp);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const errorMsg = error ? (
    <div>
      <div className="flex justify-center m-8">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-9xl text-violet" />
      </div>
      <h1 className="text-xl text-center">Our mice had some trouble</h1>
      <p className="text-sm text-center">The error we experienced is '{error.message}'. This may be a temporary problem, refresh your page to retry loading the list of hotels and their prices.</p>
    </div>
  ) : null;

  let myRefs: React.MutableRefObject<null | HTMLDivElement>[] = [];
  for (let i = 0; i < (response?.hotels?.length ?? 0); i++) {
    myRefs.push(createRef());
  }
  const items = isLoaded && !error ? response?.hotels.map((hotel, index) => {
    return (
      <PriceCarouselItem myRef={myRefs[index]} hotel={hotel.hotel} min={hotel.min} max={hotel.max} lastUpdated={hotel.lastUpdated} key={index} myKey={index} />
    )
  }) : null;

  const spinner = isLoaded ? null : <Spinner small={true}/>;

  const onNext = () => {
    const x = myRefs[current+1].current
    if (x) {
      x.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      setCurrent(current+1);
    }
  };

  const onPrev = () => {
    const x = myRefs[current-1].current
    if (x) {
      x.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      setCurrent(current-1);
    }
  };
  const middleAbsoluteClasses = "top-[50%] translate-y-[-50%]"; // https://stackoverflow.com/a/28456704/492186
  const navBtnClass =
    "absolute text-2xl  border-shamrock p-2 px-2 shadow-lg " +
    "cursor-pointer text-shamrock-light bg-white " +
    "hover:bg-shamrock-white hover:text-shamrock-light " + middleAbsoluteClasses;

  const leftNavBtn = current != 0 ? (
    <a onClick={onPrev} className={navBtnClass + " left-0 rounded-r-lg border-t-2 border-b-2 border-r-2"}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </a>
  ) : null;

  const rightNavBtn = current != (response?.hotels.length ?? 0)-1 && isLoaded && !error ? (
    <a onClick={onNext} className={navBtnClass + " right-0 rounded-l-lg border-t-2 border-b-2 border-l-2"}>
      <FontAwesomeIcon icon={faArrowRight} />
    </a>
  ) : null;

  return (
    <div className="relative mx-auto max-w-[95vw] sm:max-w-xl md:max-w-3xl lg:max-w-3xl"> {/* https://stackoverflow.com/a/10487329/492186 */}
      <div className={"bg-white p-4 text-md lg:text-lg min-w-[21rem] rounded-lg shadow-lg mb-6 flex overflow-x-auto"}>
        {leftNavBtn} {rightNavBtn}
        {errorMsg}
        {spinner}
        {items}
      </div>
    </div>
  );
}

export default PriceCarousel;