import React, { useEffect, useState } from 'react';

import Navbar from './Navbar';
import Hero from './Hero';
import FeaturedDeal from './FeaturedDeal';
import Footer from './Footer';
import Wrapper from './Wrapper';
import SearchBox, { genSearchParams, SearchSettings } from './SearchBox';
import ResultsList, { SearchResponse } from './ResultsList';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleInfo, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import API_URI from './utils';
import {Helmet} from "react-helmet";
import Chart from "react-apexcharts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, ComposedChart, ReferenceLine, Label } from 'recharts';
import { Link, useSearchParams } from 'react-router-dom';
import SearchResultItem from './SearchResultItem';
import { getHotelTitle } from './utils';
import PeopleCount from './SearchBox/PeopleCount';
import Select, { SingleValue } from 'react-select'

type FeaturedDealHistoryDataPoint = {
  date: string;
  cheapest_value: number | null;
  cheapest_moderate: number | null;
  cheapest_deluxe: number | null;
};

type FeaturedDealHistoryResponse = {
  series: FeaturedDealHistoryDataPoint[];
};

type Props = {
};

const moneyFormatter = (value: number | string): string => {
  if (typeof value === 'string') {
    return moneyFormatter(parseFloat(value));
  } else {
    const intl = new Intl.NumberFormat('en').format(Math.round(value));
    return "£" + intl;
  }
};

function InflationPage(props: Props) {

  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [response, setResponse] = useState<FeaturedDealHistoryResponse | null>(null);

  useEffect(
    () => {
      fetchInflationData();
    },
    []
  )


  function fetchInflationData() {
    // After 200ms of waiting, show spinner. Otherwise update "instaneously" for nicer UX.
    const timeoutID = setTimeout(() => setIsLoaded(false), 200);

    fetch(API_URI + "/featured_deal_history")
      .then(res => res.json())
      .then(
        (response) => {
          clearTimeout(timeoutID);
          setIsLoaded(true);
          setResponse(response);
        },
        (error) => {
          clearTimeout(timeoutID);
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  let content = null;

  if (error) {
    content = (
      <>
        <div className="flex justify-center m-8">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-9xl text-violet" />
        </div>
        <h1 className="text-xl text-center">Our mice had some trouble</h1>
        <p className="text-sm text-center">The error we experienced is '{error.message}'. This may be a temporary problem, retry later or change your search settings.</p>
      </>
    );
  } else if (!isLoaded) {
    content = (<Spinner />);
  } else if (response == null || response.series.length == 0) {
    content = (
      <>
        <div className="flex justify-center m-8">
          <FontAwesomeIcon icon={faCircleExclamation} className="text-9xl text-shamrock" />
        </div>
        <h1 className="text-xl text-center">Our mice weren't able to find any results this time</h1>
        <p className="text-sm text-center">Try again later.</p>
      </>
    )
  } else {

    content = (
      <div className="h-[960px] ">
        <ResponsiveContainer>
          <ComposedChart
            width={1280}
            height={960}
            data={response.series}
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date">
              <Label
                    style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        marginRight: "2pt"
                    }}
                  dy={20}
                  value={"Date"} />
            </XAxis>
            <YAxis tickFormatter={moneyFormatter}>
              <Label
                  style={{
                      textAnchor: "middle",
                      fontSize: "130%",
                      marginRight: "2pt"
                  }}
                dx={-40}
                angle={270}
                value={"Price (£)"} />
            </YAxis>
            <Line type="monotone" dataKey="cheapestValue" stroke="#07b01b" dot={false} strokeWidth={2} />
            <Line type="monotone" dataKey="cheapestModerate" stroke="#076cb0" dot={false} strokeWidth={2} />
            <Line type="monotone" dataKey="cheapestDeluxe" stroke="#b04807" dot={false} strokeWidth={2} />
            {/* @ts-ignore */}
            <Tooltip formatter={moneyFormatter} />
            <Legend wrapperStyle={{
                paddingTop: "10pt"
            }}/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }

  // TODO: Move to utils.
  const linkStyle = 'text-slate-600 border-b-2 border-slate-800 hover:border-0';

  return (
    <>
      <Navbar isFrontPage={false} isPriceTrackerPage={true} />
      <Helmet>
          <title>{"Holiday Price Tracker - Mousetrack.co.uk"}</title>
      </Helmet>
      <Wrapper className="border-grey-200 border-y-2 py-4">
        <h1 className="text-3xl my-8">Holiday Price Tracker for Walt Disney World</h1>
        <p className="mb-4">
          The chart below shows the cheapest holiday packages that we have
          featured on our home page on each day of the year. It should give
          a good gauge of when is the best time of the year to shop for a
          holiday and when is not so good. It also shows how prices are evolving,
          and can give a good gauge of holiday inflation.
        </p>
        <p className="mb-4">
          The prices in this chart reflect a full 14 day holiday for 2 adults.
          With each line showing a different kind of resort. The value
          resorts include All Star Music, All Star Sports, All Star Movies,
          Pop Century and Art of Animation (though you will only find the first
          three in these deals as the latter are more expensive). The moderate
          resorts include Coronado Springs, Caribbean Beach and others. The
          deluxe resorts include Animal Kingdom Lodge, Beach Club, Grand Floridian
          and more.
        </p>
        <p className="mb-4">
          If you want to check out the current featured deals, head over to
          our <Link to="/" className={linkStyle}>front page</Link>. <a href="mailto:contact@mousetrack.co.uk"
             className={linkStyle}>
          Get in touch</a> if you have any questions or feedback.
        </p>
      </Wrapper>
      <Wrapper>
        <section className="pt-4">
          {content}
        </section>
        <Footer />
      </Wrapper>
    </>
  );
}

export default InflationPage;