import { useState } from "react";
import { SearchSettings } from "./SearchBox";
import SearchResultItem, { SearchResult } from "./SearchResultItem";


export type SearchResponse = {
  results: SearchResult[];
  warnings: string[];
}

type Props = {
  data: SearchResponse | null;
  settings?: SearchSettings;
};

// Some notes when thinking about to display data here.
//
// For the common case where the user wants to see all available hotels, we
// will return results which use the cheapest flight
// (maybe we can throw in a flight with multiple stops result in there too if it's cheaper, or
//  we can give a tip modal to suggest changing the filter to get those results)
//
// This will be adjusted using filters, so if the user selects a particular hotel,
// then we can focus in on the different flights.

function ResultsList(props: Props) {
  let data = props.data;
  if (!data) {
    return null;
  }
  let resultsLen = data.results.length;

  // TODO: CAn we pick a better key for this?  see https://reactjs.org/docs/lists-and-keys.html#keys
  let cheapestPrice = -1;
  const items = data.results
    .sort((a, b) => {
      const diff = a.totalPrice.lowerBound - b.totalPrice.lowerBound;
      if (diff != 0) {
        return diff;
      }

      // In case the prices are the same we want to have a stable sort on other
      // properties.
      // https://stackoverflow.com/a/51169/492186
      return ('' + a.hotel?.resortType).localeCompare('' + b.hotel?.resortType);
    })
    .map(
      (result, index) => {
        const isCheapest = cheapestPrice == -1 || cheapestPrice == result.totalPrice.lowerBound;
        if (isCheapest) { cheapestPrice = result.totalPrice.lowerBound; }
        return <SearchResultItem data={result} key={index} isFirst={index == 0} isCheapest={isCheapest} isLast={index == resultsLen - 1} settings={props.settings} />
      }
    );

  return (
    <div>
      {items}
    </div>
  );
}

export default ResultsList;