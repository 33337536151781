import { faAngleDown, faAngleUp, faInfoCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useState } from "react";
import Button from "./Button";
import { SearchSettings } from "./SearchBox";
import { getAirportShortCode, getAirportTitle, parseDate, renderPrice, renderPriceShort } from "./utils";

export type PriceBounds = {
  lowerBound: number;
  upperBound: number;
}

export type FlightResult = {
  totalPrice: PriceBounds; // For all passengers.
  origin: string;
  destination: string;
  airlineImageUrl: string | null;
  stops: number; // Number of times you need to change planes.
  bookUrl: string;
}

export type PriceSplit = {
  startDate: string;
  endDate: string;
  nights: number;
  price: number; // price per night
}

export type HotelResult = {
  totalPrice: PriceBounds;
  isSplitPrice: boolean;
  priceSplits?: PriceSplit[]; // 30 May - 2 June: £200 per night. etc.
  resortName: string;
  resortType: string;

  bookUrl: string;
}

export type TicketResult = {
  totalPrice: PriceBounds;
  adultPrice: number;
  childPrice: number;
  durationDays: number;

  bookUrl: string;
}

export type SearchResult = {
  flight: FlightResult | null;
  hotel: HotelResult | null;
  ticket: TicketResult | null;
  // Sometimes we may not know with high enough confidence an exact price,
  // or we may want to show the same hotel/flights/tickets but from different
  // providers (TODO on how to show this exactly, might actually be best as a separate result item).
  totalPrice: PriceBounds;

  startDate: string;
  durationDays: number;
}

type SearchResultSavings = {
  priceSplitSavings: number | null; // Number of £ we can save by booking separately.
}

function findSavings(data: SearchResult): SearchResultSavings {
  let result: SearchResultSavings = { priceSplitSavings: null };

  if (data.hotel) {
    let lowerBound = data.hotel.totalPrice.lowerBound;
    // Check if our splits added up together give a smaller price, if so
    // and they don't require too many separate bookings suggest them.
    let splitsLen = data.hotel.priceSplits?.length ?? 0;
    if (splitsLen > 1 && splitsLen < 4 && data.hotel.priceSplits != undefined) {
      let totalSplitsPrice = getTotalSplitsPrice(data);
      let saving = lowerBound - totalSplitsPrice;
      if (saving > 50) {
        result.priceSplitSavings = saving;
      }
    }
  }

  return result;
}

type Props = {
  data: SearchResult;
  isFirst?: boolean;
  isCheapest?: boolean;
  isLast?: boolean;
  settings?: SearchSettings;
  isForHistory?: boolean;
};

function showDataUnavailable(title: string, message: string) {
  return (
    <div>
      <p className="text-lg font-semibold grid grid-cols-2 w-3/4">
        {title}
      </p>
      <div className="px-6 text-lg ">
        <p className="text-honey">
          <FontAwesomeIcon icon={faTriangleExclamation} className="px-2" />
          No {message} available for the dates selected.
        </p>
      </div>
    </div>
  )
}

function renderPriceBounds(price: PriceBounds) {
  if (price.lowerBound == price.upperBound) {
    return (
      <span>{renderPrice(price.lowerBound)}</span>
    );
  } else {
    return (
      <span>{renderPrice(price.lowerBound)} - {renderPrice(price.upperBound)}</span>
    )
  }
}

function renderHotelDetails(props: Props, savings: SearchResultSavings) {
  if (!props.settings?.hotels) {
    return;
  }

  if (props.data.hotel != null) {
    const splits = props.data.hotel.priceSplits ? props.data.hotel.priceSplits.map(
      (split, index) => {
        return <p className="grid grid-cols-2 sm:w-3/4">
          <span>
            {parseDate(split.startDate).toLocaleString('default', { day: 'numeric' })}
            &nbsp;
            {parseDate(split.startDate).toLocaleString('default', { month: 'short' })}
            &nbsp;({split.nights} nights)
          </span>
          <span>{renderPrice(split.price)} per night</span>
        </p>
      }
    ) : [];

    let bookingsLen = props.data.hotel.priceSplits?.length;
    // We scrape 7-day and 14-day durations, so if we only have a split price
    // for a hotel in that duration we know it's "only" available as a split
    // booking. For other durations we use softer language.
    let warningPhrase = props.data.durationDays == 7 || props.data.durationDays == 14 ? "is only" : "may only be";
    let totalWarning = props.data.hotel.isSplitPrice && bookingsLen != 1 ? (
      <p className="text-honey">
        <FontAwesomeIcon icon={faTriangleExclamation} className="pr-2" />
        This hotel {warningPhrase} available via {bookingsLen} separate bookings.
      </p>
    ) : null;

    let adviceSavings = savings.priceSplitSavings ?? 0;
    let showAdvice = adviceSavings > 0 && !props.data.hotel.isSplitPrice;
    let totalWithSavings = showAdvice ? (
      <>
        <p className="text-shamrock">
          <FontAwesomeIcon icon={faInfoCircle} className="pr-2" />
          Save {renderPrice(adviceSavings)} by making {bookingsLen} separate bookings.
        </p>
        <p className={"grid grid-cols-2 sm:w-3/4 text-shamrock"}><span>Total with savings</span><span>{renderPrice(props.data.hotel.totalPrice.upperBound - adviceSavings)}</span></p>
        <p className={"grid grid-cols-2 sm:w-3/4"}><span>Total without savings</span>{renderPrice(props.data.hotel.totalPrice.upperBound)}</p>
      </>
    ) :
      <>
        <p className={"grid grid-cols-2 sm:w-3/4"}><span>Total</span>{renderPriceBounds(props.data.hotel.totalPrice)}</p>
      </>;

    let totalSplitsPrice = getTotalSplitsPrice(props.data);
    let saving = props.data.hotel.totalPrice.lowerBound - totalSplitsPrice;
    let singleBookingNote = !showAdvice && saving > 0 ? (
      <>
        <p className={"grid grid-cols-2 sm:w-3/4 text-gray-400"}>
          <span title={"This is how much you would save by making " + bookingsLen + " separate bookings."}>Single-booking fee</span><span>{renderPrice(saving)}</span>
        </p>
      </>
    ) : null;

    return (
      <div>
        <p className="text-md sm:text-lg font-semibold grid grid-cols-2 sm:w-3/4">
          Hotel
        </p>
        <div className="px-6 text-md sm:text-lg ">
          <p className="text-gray-400">{splits}</p>
          {singleBookingNote}
          {totalWithSavings}
          {totalWarning}
        </div>
      </div>
    )
  }

  if (props.settings?.hotels) {
    // Show a message to explain that flights are missing for the selected dates.
    return showDataUnavailable("Hotel", "hotel prices");
  }

  return <></>;
}

function renderFlightDetails(props: Props) {
  if (!props.settings?.flights) {
    return;
  }

  if (props.data.flight != null) {
    return (
      <div>
        <p className="text-md sm:text-lg font-semibold grid grid-cols-2 sm:w-3/4">
          Flight
        </p>
        <div className="px-6 text-md sm:text-lg ">
          <p className="text-gray-400 grid grid-cols-2 sm:w-3/4">
            <span>Origin</span><span>{getAirportTitle(props.data.flight.origin)}</span>
            <span>Destination</span><span>{getAirportTitle(props.data.flight.destination)}</span>
            <span>Stops</span><span>{props.data.flight.stops == 0 ? "Non-stop" : props.data.flight.stops + " stops"}</span>
          </p>
          <p className="grid grid-cols-2 sm:w-3/4"><span>Total</span>{renderPriceBounds(props.data.flight.totalPrice)}</p>
        </div>
      </div>
    )
  }

  if (props.settings?.flights) {
    // Show a message to explain that flights are missing for the selected dates.
    return showDataUnavailable("Flight", "flight prices");
  }

  return <></>;
}

function renderTicketDetails(props: Props) {
  if (!props.settings?.tickets) {
    return;
  }

  if (props.data.ticket != null) {
    return (
      <div>
        <p className="text-md sm:text-lg font-semibold grid grid-cols-2 sm:w-3/4">
          Tickets
        </p>
        <div className="px-6 text-md sm:text-lg ">
          <p className="text-gray-400 grid grid-cols-2 sm:w-3/4">
            <span>{props.settings.adults} adults</span><span>{renderPrice(props.data.ticket.adultPrice)} per adult</span>
            <span>{props.settings.children} children</span><span>{renderPrice(props.data.ticket.childPrice)} per child</span>
          </p>
          <p className="grid grid-cols-2 sm:w-3/4"><span>Total</span>{renderPriceBounds(props.data.ticket.totalPrice)}</p>
        </div>
      </div>
    )
  }

  if (props.settings?.tickets) {
    // Show a message to explain that tickets are missing for the selected dates.
    return showDataUnavailable("Ticket", "ticket prices");
  }

  return <></>;
}

function joinWords(words: string[]) {
  if (words.length == 1) {
    return words[0];
  }

  if (words.length == 2) {
    return words[0] + " and " + words[1];
  }

  return words.join(", ");
}

function identifyBookUrl(url: string) {
  if (url.includes("disneyholidays.co.uk/walt-disney-world")) {
    return {
      title: "The Walt Disney Travel Company",
      logo: "wdt_co_logo.png"
    }
  }

  if (url.includes("google.com/flights")) {
    return {
      title: "Google Flights",
      logo: "google_logo.svg"
    }
  }

  if (url.includes("tui.co.uk")) {
    return {
      title: "TUI",
      logo: "tui_logo.png"
    }
  }
}

function renderBookLinks(props: Props) {
  let links = new Map();
  if (props.settings?.hotels && props.data.hotel != null) {
    links.set(props.data.hotel.bookUrl, ["hotel"]);
  }
  if (props.settings?.flights && props.data.flight != null) {
    let url = props.data.flight.bookUrl;
    if (links.has(url)) {
      links.get(url).push("flights");
    } else {
      links.set(url, ["flights"]);
    }
  }
  if (props.settings?.tickets && props.data.ticket != null) {
    let url = props.data.ticket.bookUrl;
    if (links.has(url)) {
      links.get(url).push("tickets");
    } else {
      links.set(url, ["tickets"]);
    }
  }

  return (
    Array.from(links).map(([key, value]) => {
      const properties = identifyBookUrl(key);
      return <div className="flex items-center text-sm sm:text-md md:text-lg font-semibold mt-2">
        <div className="min-w-[2.5rem] w-10 flex items-center justify-center text-center mr-2">
          <img src={"./images/" + properties?.logo} className="inline" />
        </div>
        <span>Book {joinWords(value)} using {properties?.title}</span>
        <Button primary={false} className="ml-auto" href={key} target="_blank">Book</Button>
      </div>;
    }
    )
  )
}

function getTotalSplitsPrice(data: SearchResult): number {
  return data.hotel?.priceSplits?.reduce((prev, curr) => prev + curr.price * curr.nights, 0) ?? 0;
}

function renderHeadlinePrice(props: Props, savings: SearchResultSavings): ReactElement {
  let priceColour = "";
  if (props.data.hotel?.isSplitPrice && props.data.hotel?.priceSplits?.length != 1) {
    // When we only have a "split price", as in we only have price data for the
    // single days rather than a 7-day or 14-day block we colour the price orange.
    // XXX: We don't do this when there is no splits, so far this seems to indicate
    // that we can simply sum up the prices and assume that the price we get
    // will be the same on Disney's website when we book for that duration.
    priceColour = "text-honey";
  } else if (props.isCheapest) {
    priceColour = "text-shamrock";
  }

  let lowerBound = props.data.totalPrice.lowerBound;
  // Apply possible savings.
  if (savings.priceSplitSavings) {
    lowerBound -= savings.priceSplitSavings;
  }
  const upperBound = props.data.totalPrice.upperBound;

  const upperBoundRender = lowerBound < upperBound ? (
    <>
    <span className="block text-center md:inline"> - </span>
    <span>{renderPriceShort(upperBound)}</span>
    </>
  ) : null;

  return (
    <p className={priceColour}>
      {renderPriceShort(lowerBound)}{upperBoundRender}
    </p>
  );
}

function renderShortSummary(props: Props): ReactElement {
  let middleDot = (
    <span className="px-2">·</span>
  );
  let detail = null;
  if (props.data.hotel) {
    let hotel = (
      <span>{props.data.hotel.resortName}</span>
    );
    if (props.data.hotel?.isSplitPrice) {
      detail = (
        <>
          {hotel}
          {middleDot}
          <span>{props.data.hotel.priceSplits?.length} bookings</span>
        </>
      );
    } else {
      detail = hotel;
    }
  }
  else if (props.data.flight) {
    detail = (
      <>
        <span>{getAirportShortCode(props.data.flight.origin)} to {getAirportShortCode(props.data.flight.destination)}</span>
        {middleDot}
        <span>{props.data.flight.stops == 0 ? "Non-stop" : (props.data.flight.stops.toString() + " stops")}</span>
      </>
    )
  }

  return (
    <>
      <span>{props.settings?.duration} {props.data.hotel ? "nights" : "days"}</span>
      {detail != null ? middleDot : null}
      {detail}
    </>
  )
}

function SearchResultItem(props: Props) {
  const [expanded, setExpanded] = useState<boolean>(props.data.hotel?.resortType == props.settings?.highlight);

  let headlineTitle = "";
  let headlineImage = null;
  if (props.settings?.hotels && props.data.hotel != null) {
    headlineTitle = props.data.hotel.resortName;

    if (props.data.hotel?.resortType != null) {
      headlineImage = (
        <img src={"/images/" + props.data.hotel?.resortType + ".jpg"} className="rounded-2xl h-full inline" />
      )
    }
  } else if (props.settings?.flights && props.data.flight != null) {
    headlineTitle = "Flight to Orlando";
    headlineImage = (
      <img src={"/images/OrlandoInternationalAirport.webp"} className="rounded-2xl h-full inline" />
    )
  } else {
    headlineTitle = `${props.data.durationDays}-day Tickets to Walt Disney World`;
  }

  let btnIcon = expanded ? faAngleUp : faAngleDown;
  let needsTopRounding = expanded || props.isFirst;
  let needsBottomRounding = expanded || props.isLast;

  const savings = findSavings(props.data);
  const headlinePrice = renderHeadlinePrice(props, savings);

  let details = null;
  if (expanded) {
    details = (
      <>
        <div className="p-4 border-gray-300 border-t">
          {renderHotelDetails(props, savings)}
          {renderFlightDetails(props)}
          {renderTicketDetails(props)}
        </div>
        <div className="px-4 pb-4 pt-2 border-gray-300 border-t">
          {renderBookLinks(props)}
        </div>
      </>
    );
  }

  let button = (
    <Button primary={false} className="px-2 py-2.5 ml-8 mr-4" onClick={() => setExpanded(!expanded)}>
      <FontAwesomeIcon icon={btnIcon} className="px-2" />
    </Button>
  );

  return (
    <div className={"border border-grey-300 " + (needsTopRounding ? " rounded-t-2xl " : "") + (needsBottomRounding ? " rounded-b-2xl " : "") + (expanded ? " my-4 " : "")}>
      <div className="h-18 grid grid-cols-[1fr_auto] p-3 px-4">
        <div className="mr-8 max-h-32 sm:h-20 flex items-center">
          {headlineImage}
          <div className={"hidden sm:block ml-3 mr-2"}><span className="text-md sm:text-xl ">{headlineTitle}</span></div>
        </div>
        <div className="text-xl font-semibold flex items-center">
          {headlinePrice}

          {props.isForHistory ? (<div className="px-2"></div>) : button}
        </div>
        <div className="sm:hidden text-xs text-gray-400 col-span-2 mt-2">
          {renderShortSummary(props)}
        </div>
      </div>
      {details}
    </div>
  );
}

export default SearchResultItem;