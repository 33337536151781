
import Navbar from './Navbar';
import Hero from './Hero';
import FeaturedDeals from './FeaturedDeals';
import Footer from './Footer';
import Wrapper from './Wrapper';
import { Helmet } from 'react-helmet';


function FrontPage() {


  return (
    <>
      <Helmet>
        <title>Mousetrack.co.uk: Find Disney World holiday prices, deals and discounts</title>
      </Helmet>
      <Navbar isFrontPage={true}/>
      <Hero/>
      <Wrapper>
        <FeaturedDeals/>
        <Footer/>
      </Wrapper>
    </>
  );
}

export default FrontPage;