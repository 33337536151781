type Props = {
  className?: string;
};

const Wrapper: React.FC<Props & React.HTMLAttributes<HTMLButtonElement>> = (props) => (
  <header className={(props.className ?? "")}>
    <div className="max-w-screen-xl w-full mx-auto px-4">
      {props.children}
    </div>
  </header>
)

export default Wrapper;