
import PriceCarousel from './PriceCarousel';
import SearchBox from './SearchBox';
import Wrapper from './Wrapper';

function Hero() {
  const images = [
    <img src="images/swan.webp" alt="Walt Disney World Swan Hotel"/>,
    <img src="images/monorail_night.webp" alt="Monorail at Epcot illuminated by neon blue lights"/>,
    <img src="images/yachtclub_morning.webp" alt="Sunrise at the Yacht &amp; Beach Club"/>,
    <img src="images/epcot_sunset.webp" alt="Sunset in Epcot"/>,
    <img src="images/epcot_night.webp" alt="Epcot at night"/>,
    <img src="images/springs_balloon_night.webp" alt="Disney Springs Balloon at night"/>,
    <img src="images/monorail_closeup.webp" alt="Closeup of the green monorail"/>,
  ];
  const imagesDesc = [
    "Swan Hotel",
    <span>Monorail at night by <a href="https://www.reddit.com/r/WaltDisneyWorld/comments/xukmxg/my_favorite_shot_of_the_monorail/">u/jarjar_binx52</a></span>,
    <span>Sunrise at Yacht &amp; Beach Club by <a href="https://www.reddit.com/r/WaltDisneyWorld/comments/xk4ga7/good_morning_yacht_beach_club/">u/xXMojoRisinXx</a></span>,
    <span>Sunset at Epcot by <a href="https://www.reddit.com/r/WaltDisneyWorld/comments/xklb89/epcot_sunset_from_last_night/">u/deemagicgurl</a></span>,
    <span>Epcot at night by <a href="https://www.reddit.com/r/WaltDisneyWorld/comments/zgryko/spaceship_earth_nighttime_edition/">u/Kraftndinner</a></span>,
    <span>Springs Balloon by <a href="https://www.reddit.com/r/WaltDisneyWorld/comments/ydk6pk/got_a_nice_shot_of_the_disney_springs_balloon/">u/darkhorse1821</a></span>,
    <span>Monorail Closeup by <a href="https://www.reddit.com/r/WaltDisneyWorld/comments/z66gsd/a_picture_of_my_favorite_ride_on_property_the/">u/Traviscat</a></span>,
  ]

  const index = Math.floor(Math.random() * images.length);

  return (
    <Wrapper className="bg-cultured">
      <div className="grid grid-cols lg:grid-cols-3 lg:pt-16 pb-8 gap-x-8">
        <div className="lg:col-span-2">
          <h1 className="text-4xl lg:text-5xl leading-tight lg:mb-2">
            Helping you
              <span className="before:block before:absolute before:inset-0.5 before:-skew-y-3 before:top-[0.1rem] before:-bottom-[0.35rem] before:bg-honey relative inline-block text-3xl lg:text-4xl mx-3" aria-hidden="true">
                <span className="relative text-white text-4xl lg:text-5xl">&nbsp;save&nbsp;</span>
              </span>
            money on Disney!
          </h1>
          <p className="text-sm mb-6">Explore historical Walt Disney World prices and see if you're getting the best deal on your holiday.</p>
          <PriceCarousel />
          <p className="text-sm mb-6">Alternatively, if you have dates in mind then find the cheapest holiday packages below.</p>
          <SearchBox isBoxed={true}/>
        </div>
        <div className="order-first lg:order-last hidden lg:block" /* Outer div which might have a height as big as col 1, which would break image's border radius */>
          <div className="relative rounded-lg overflow-hidden group">
            {images[index]}
            <span className="absolute bottom-4 left-0 px-4 bg-cultured rounded-r-lg opacity-0 group-hover:opacity-95 group-active:opacity-95 transition duration-250">
              {imagesDesc[index]}
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Hero;