
function Footer() {
  return (
    <footer className="p-8 border-t-2 border-zinc-200 mt-16 text-zinc-500 font-light text-xs ">
      <p>This website is in no way affiliated with The Walt Disney Company or its subsidiaries. The prices shown here are based on data extracted from multiple different third-party sources, while all effort has been taken to ensure this data is accurate there are no guarantees it is.</p>

      <p className="text-center mt-4">© Mouse Track 2023 • contact@mousetrack.co.uk</p>
    </footer>
  );
}

export default Footer;