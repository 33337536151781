import { faAngleDown, faArrowRightArrowLeft, faCalendarAlt, faHotel, faPlane, faTicket, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, forwardRef, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import { ReactComponent as Ellipse } from './ellipse.svg';

import "react-datepicker/dist/react-datepicker.css";
import "./CustomCalendar.css";

import Select, { ActionMeta, OnChangeValue } from 'react-select';

export interface AirportOption {
  readonly value: string;
  readonly label: string;
}

interface GroupedOption {
  readonly label: string;
  readonly options: readonly AirportOption[];
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

// These are matched to mouselib/lib::Airport
const londonOptions: readonly AirportOption[] = [
  { value: 'AnyLondon', label: 'London (All airports)', },
  { value: 'LondonHeathrow', label: 'London Heathrow', },
  { value: 'LondonGatwick', label: 'London Gatwick', },
  { value: 'LondonStansted', label: 'London Stansted', },
];

const otherOptions: readonly AirportOption[] = [
  { value: 'Manchester', label: 'Manchester', },
];

const groupedOptions: readonly GroupedOption[] = [
  {
    label: 'London',
    options: londonOptions,
  },
  {
    label: 'Other',
    options: otherOptions,
  },
];

type Props = {
  defaultSelection?: string | null;
  onChange: (newValue: AirportOption | null) => void;
  highlightError: boolean;
};


const OriginSelector = (props: Props) => {
  const findPred = (element: AirportOption) => element.value == props.defaultSelection;
  const defaultValue = londonOptions.find(findPred) ?? otherOptions.find(findPred);

  return (
    <div className={"rounded-md border-zinc-300 border flex items-center h-12 hover:border-zinc-400 " + (props.highlightError ? " outline-red-500 outline-2 outline " : "")}>
      <Ellipse className={"inline mx-4 my-4 h-4 w-4 stroke-black fill-white" }/>

      <Select<AirportOption, false, GroupedOption>
        defaultValue={defaultValue}
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
        classNamePrefix="react-select"
        openMenuOnFocus={true}
        // The `margin-top: 3px` trick is used to make sure the input is
        // centered to the icon. This same trick is used by Google Flights.
        className="mt-[-3px] w-full"
        aria-label="Where from?" placeholder="Where from?"
        onChange={(newValue: OnChangeValue<AirportOption, false>, _actionMeta: ActionMeta<AirportOption>) => {
          props.onChange(newValue);
        }}
      />
    </div>
  );
};

export default OriginSelector;