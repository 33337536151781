import { faHotel, faPlane, faTicket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

type Props = {
  defaultFlights: boolean;
  defaultTickets: boolean;
  defaultHotels: boolean;
  onUpdate: (flights: boolean, tickets: boolean, hotels: boolean) => void;
};


function TypeSelector(props: Props) {
  const [flightsSelected, setFlightsSelected] = useState<boolean>(props.defaultFlights);
  const [ticketsSelected, setTicketsSelected] = useState<boolean>(props.defaultTickets);
  const [hotelsSelected, setHotelsSelected] = useState<boolean>(props.defaultHotels);

  let btnClass = "py-0.5 pr-2.5 border border-1 hover:border hover:border-zinc-400";
  let btnClassSelected = " border-cultured-dark border border-1 bg-cultured ";

  let middleBorderClear = (flightsSelected ? " border-l-transparent " : "") + (hotelsSelected ? " border-r-transparent " : "");

  function onClick(isFlights: boolean, isTickets: boolean, isHotels: boolean) {
    if (isFlights) {
      if (flightsSelected && !ticketsSelected && !hotelsSelected) { return; }

      setFlightsSelected(!flightsSelected);
      props.onUpdate(!flightsSelected, ticketsSelected, hotelsSelected);
    }
    if (isTickets) {
      if (!flightsSelected && ticketsSelected && !hotelsSelected) { return; }

      setTicketsSelected(!ticketsSelected);
      props.onUpdate(flightsSelected, !ticketsSelected, hotelsSelected);
    }
    if (isHotels) {
      if (!flightsSelected && !ticketsSelected && hotelsSelected) { return; }

      setHotelsSelected(!hotelsSelected);
      props.onUpdate(flightsSelected, ticketsSelected, !hotelsSelected);
    }
  }

  return (
    <div className="inline mr-4 ">
      <button className={btnClass + (flightsSelected ? btnClassSelected : " border-transparent ") + " rounded-l-full"}
        onClick={() => { onClick(true, false, false) }}>
        <FontAwesomeIcon icon={faPlane} className="px-2.5" />
        <span>Flights</span>
      </button>
      <button className={btnClass + (ticketsSelected ? btnClassSelected : " border-transparent ") + middleBorderClear}
        onClick={() => { onClick(false, true, false) }}>
        <FontAwesomeIcon icon={faTicket} className="px-2.5" />
        <span>Tickets</span>
      </button>
      <button className={btnClass + (hotelsSelected ? btnClassSelected : " border-transparent ") + " rounded-r-full"}
        onClick={() => { onClick(false, false, true) }}>
        <FontAwesomeIcon icon={faHotel} className="px-2.5" />
        <span>Hotels</span>
      </button>
    </div>
  );
}

export default TypeSelector;