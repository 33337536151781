
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  className?: string;
  primary: boolean;
  onClick?: () => void;
  href?: string;
  target?: string;
  size?: string;
}

function getButtonClasses(props: Props): string {
  if (props.primary) {
    return " bg-shamrock text-white hover:bg-shamrock-light active:shadow-[inset_0_0px_4px_1px_rgb(0_0_0_/_0.5)] ";
  }
  return " border-zinc-300 border text-shamrock hover:bg-shamrock/10 hover:text-shamrock-light ";
}

// https://stackoverflow.com/a/61143325
const Button: React.FC<Props & React.HTMLAttributes<HTMLButtonElement>> = (props) => {
  const content = (
    <p className="mt-[-3px]">{props.children}</p>
  );
  const className = "rounded-full p-1.5 px-6 font-medium tracking-tight " + (getButtonClasses(props)) + (props.className ?? "") + (" " + (props.size ?? " text-md sm:text-lg md:text-xl ") + " ");

  if (props.href != undefined) {
    return (
      <a className={className} onClick={props.onClick} href={props.href} target={props.target}>
        {content}
      </a>
    );
  } else {
    return (
      <button className={className} onClick={props.onClick}>
        {content}
      </button>
    );
  }
}

export default Button;