import React, { useEffect, useState } from 'react';

import Navbar from './Navbar';
import Hero from './Hero';
import FeaturedDeal from './FeaturedDeal';
import Footer from './Footer';
import Wrapper from './Wrapper';
import SearchBox, { genSearchParams, SearchSettings } from './SearchBox';
import ResultsList, { SearchResponse } from './ResultsList';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleInfo, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import API_URI from './utils';
import {Helmet} from "react-helmet";

type Props = {
};

function SearchPage(props: Props) {
  const [settingsState, setSettingsState] = useState<SearchSettings | null>();

  let title = "Cheapest holidays";
  let subtitle = "Sorted by the total price of the holiday for the selected duration of stay.";
  if (settingsState?.flights && !settingsState?.tickets && !settingsState?.hotels) {
    title = "Cheapest flights";
  } else if (settingsState?.tickets && !settingsState?.flights && !settingsState?.hotels) {
    title = "Cheapest tickets";
  } else if (settingsState?.hotels && !settingsState?.flights && !settingsState?.tickets) {
    title = "Cheapest hotels";
  }

  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [response, setResponse] = useState<SearchResponse | null>(null);


  function fetchSearchData(settings: SearchSettings) {
    setSettingsState(settings);
    // After 200ms of waiting, show spinner. Otherwise update "instaneously" for nicer UX.
    const timeoutID = setTimeout(() => setIsLoaded(false), 200);

    fetch(API_URI + "/search?" + genSearchParams(settings).toString())
      .then(res => res.json())
      .then(
        (response) => {
          clearTimeout(timeoutID);
          setIsLoaded(true);
          setResponse(response);
        },
        (error) => {
          clearTimeout(timeoutID);
          setIsLoaded(true);
          setError(error);
        }
      )
  }


  const dummyResult = {
    flight: {
      totalPrice: 1004.0,
      origin: "LON",
      destination: "MCO",
      airlineImageUrl: null,
      stops: 0,
      bookUrl: "https://flights.google.com"
    },
    hotel: {
      totalPrice: 2345,
      priceSplits: [
        {
          startDate: new Date(2022, 5, 30),
          endDate: new Date(2022, 6, 2),
          price: 104.60
        },
        {
          startDate: new Date(2022, 6, 3),
          endDate: new Date(2022, 6, 6),
          price: 112.45
        },
      ],
      resortName: "Disney's All-Star Music Resort",
      resortImageUrl: "AllStarMusic.jpg",
      bookUrl: "https://www.disneyholidays.co.uk/walt-disney-world/",
    },
    ticket: {
      totalPrice: 1112,
      adultPrice: 500,
      childPrice: 123,
      durationDays: 14,
      bookUrl: "https://www.disneyholidays.co.uk/walt-disney-world/"
    },
    totalPriceLowerBound: 3456,
    totalPriceUpperBound: 3456,
    startDate: new Date(),
    durationDays: 14,
  };

  const dummyResults = [
    dummyResult,
    dummyResult,
    dummyResult,
    dummyResult,
    dummyResult,
    dummyResult,
  ];

  let content = null;

  if (error) {
    content = (
      <>
        <div className="flex justify-center m-8">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-9xl text-violet" />
        </div>
        <h1 className="text-xl text-center">Our mice had some trouble</h1>
        <p className="text-sm text-center">The error we experienced is '{error.message}'. This may be a temporary problem, retry later or change your search settings.</p>
      </>
    );
  } else if (!isLoaded) {
    content = (<Spinner />);
  } else if (response == null || response.results.length == 0) {
    content = (
      <>
        <div className="flex justify-center m-8">
          <FontAwesomeIcon icon={faCircleExclamation} className="text-9xl text-shamrock" />
        </div>
        <h1 className="text-xl text-center">Our mice weren't able to find any results this time</h1>
        <p className="text-sm text-center">Do try modifying the start date of your holiday, it's possible the dates you've picked have no more availability or that Disney hasn't released info for them yet.</p>
      </>
    )
  } else {
    let warning = response.warnings.map(warning => (
      <div className="p-4 mt-4 border border-zinc-300 rounded-lg text-md ">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-honey pr-2" />
        <span className="text-gray-400">{warning}</span>
      </div>
    ));

    const makeList = (resp: SearchResponse) => (
      <>
      <h1 className="text-3xl mt-8">{title}</h1>
      <p className="mb-4">{subtitle}</p>
      <ResultsList data={resp} settings={settingsState ?? undefined} />
      </>
    );

    let highlightedHotel = response.results.find((val) => val.hotel?.resortType == settingsState?.highlight);
    if (highlightedHotel && settingsState?.highlight) {
      // We are highlighting a particular hotel as part of a deal.
      // We need to find the highlighted hotel.
      let remainingResults: SearchResponse = structuredClone(response);
      remainingResults.results = remainingResults.results.filter((val) => val.hotel?.resortType != settingsState?.highlight);
      content = (
        <>
          {warning}
          <h1 className="text-3xl mt-8 mb-4">Selected deal</h1>
          <ResultsList data={{ results: [highlightedHotel], warnings: [] }} settings={settingsState ?? undefined} />
          {makeList(remainingResults)}
        </>
      )
    } else {
      content = (
        <>
          {warning}
          {makeList(response)}
        </>
      )
    }
  }

  return (
    <>
      <Navbar isFrontPage={false} />
      <Wrapper className="border-grey-200 border-y-2">
        <SearchBox isBoxed={false} onChange={(newSettings) => fetchSearchData(newSettings)} />
      </Wrapper>
      <Wrapper>
        <section className="lg:px-48 xl:px-64">
          {content}
        </section>
        <Footer />
      </Wrapper>
    </>
  );
}

export default SearchPage;