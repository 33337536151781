import { faAngleDown, faMinus, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState, FocusEvent } from 'react';
import { usePopper } from 'react-popper';
import Button from '../Button';

type Props = {
  currAdults: number;
  currChildren: number;
  onUpdate: (adults: number, children: number) => void;
};

const PeopleCount = (props: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [adults, setAdults] = useState<number>(1);
  const [children, setChildren] = useState<number>(0);
  const [menuShown, setMenuShown] = useState<boolean>(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const changeMenuVisibility = (shown: boolean, save: boolean) => {
    if (!menuShown && shown) {
      setAdults(props.currAdults);
      setChildren(props.currChildren);
    }
    if (!shown && save) {
      // Callback upwards
      props.onUpdate(adults, children);
    }
    setMenuShown(shown);
  };

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLButtonElement | HTMLDivElement>) => {
      // Based on https://muffinman.io/blog/catching-the-blur-event-on-an-element-and-its-children/.
      // Be sure that your <div> has tabindex=0 (https://stackoverflow.com/a/42764495)
      if (popperElement === null || referenceElement === null) {
        return;
      }
      const oldTarget = e.currentTarget;

      // Give browser time to focus the next element
      requestAnimationFrame(() => {
        // Check if the new focused element is a child of the drop down menu
        // or it is the drop down menu or the button.
        if (!(
          popperElement.contains(document.activeElement) ||
          document.activeElement == popperElement ||
          document.activeElement == referenceElement
        )) {
          setMenuShown(false);
        }
      });
    },
    [setMenuShown, popperElement, referenceElement]
  );

  const btnClass = "px-4 py-2 bg-shamrock/25 rounded-md text-shamrock-dark mx-2"
  const popperClass = menuShown ? "visible" : "invisible";
  return (
    <>
      <button className="rounded-md hover:bg-cultured py-1 px-2" type="button" ref={setReferenceElement} onBlur={handleBlur} onClick={() => changeMenuVisibility(!menuShown, false)}>
        <FontAwesomeIcon icon={faUser} className="pr-2" />
        {props.currAdults + props.currChildren}
        <FontAwesomeIcon icon={faAngleDown} className="pl-2" />

      </button>

      <div
        ref={setPopperElement} style={styles.popper} {...attributes.popper}
        className={"rounded-sm drop-shadow-md bg-white py-4 px-3 z-50 border-zinc-300 border focus:visible " + popperClass}
        onBlur={handleBlur}
        tabIndex={0}
      >

        <div className=" grid grid-cols-[1fr_auto] items-center gap-4">
          <span className="px-2">Adults</span>
          <div>
            <button onClick={() => setAdults(Math.max(1, adults - 1))} className={btnClass}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <span className="px-2">{adults}</span>
            <button onClick={() => setAdults(Math.min(8, adults + 1))} className={btnClass}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          <span className="px-2">Children</span>
          <div>
            <button onClick={() => setChildren(Math.max(0, children - 1))} className={btnClass}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <span className="px-2">{children}</span>
            <button onClick={() => setChildren(Math.min(8, children + 1))} className={btnClass}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        <div className="float-right mt-8">
          <Button primary={false} className="mr-4" onClick={() => changeMenuVisibility(false, false)}>Cancel</Button>
          <Button primary={false} onClick={() => changeMenuVisibility(false, true)}>Done</Button>
        </div>
      </div>
    </>
  );
};

export default PeopleCount;