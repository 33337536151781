import { faAngleDown, faArrowRightArrowLeft, faCalendarAlt, faHotel, faPlane, faTicket, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, forwardRef, useRef, useState } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./CustomCalendar.css";

import Select, { ActionMeta, OnChangeValue } from 'react-select';

interface DayOption {
  readonly value: number;
  readonly label: string;
}

interface GroupedOption {
  readonly label: string;
  readonly options: readonly DayOption[];
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    {/* <span style={groupBadgeStyles}>{data.options.length}</span> */}
  </div>
);

const commonOptions: readonly DayOption[] = [
  { value: 7, label: '7-days', },
  { value: 14, label: '14-days', },
];

let allOptions: DayOption[] = [];
for (let i = 2; i <= 21; i++) {
  allOptions.push(
    { value: i, label: i.toString() + '-days'}
  )
}

const groupedOptions: readonly GroupedOption[] = [
  {
    label: 'Common',
    options: commonOptions,
  },
  {
    label: 'All',
    options: allOptions,
  },
];

export type Props = {
  defaultStartDate?: Date | null;
  defaultDuration?: number | null;
  onChange: (newStartDate: Date, newDuration: number) => void;
}

const DEFAULT_DURATION = 14;

export function getStartDate(): Date {
  let minDate = new Date();
  minDate.setDate(minDate.getDate() + 9);
  return minDate;
}

const CustomCalendar = (props: Props) => {
  const [startDate, setStartDate] = useState(props.defaultStartDate ?? getStartDate());
  const [duration, setDuration] = useState(props.defaultDuration ?? DEFAULT_DURATION);

  const CustomInput = forwardRef<
    HTMLDivElement,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  >((props, ref) => (
    <div className="hover:bg-cultured rounded-md px-2 mx-1 cursor-pointer" onClick={props.onClick}>
      <FontAwesomeIcon icon={faCalendarAlt} className="pr-2" />
      <span>{props.value}</span>
    </div>
  ));

  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 2);

  const findPred = (element: DayOption) => element.value == props.defaultDuration;
  const defaultValue = allOptions.find(findPred) ?? commonOptions[1];

  // const [reactSelect, setReactSelect] = useState<any>(null);
  return (
    <div className="rounded-md border-zinc-300 border flex items-center h-12 hover:border-zinc-400">
      <div>
        <DatePicker
          selected={startDate}
          onChange={(date: Date) => {setStartDate(date); props.onChange(date, duration);}}
          customInput={<CustomInput />}
          minDate={getStartDate()}
          maxDate={maxDate}
          dateFormat={"dd MMM yyyy"}
          disabledKeyboardNavigation // https://stackoverflow.com/a/64200664
        />
      </div>
      <div className="border-zinc-300 border-r-2 h-6"></div>
      <div className="hover:bg-cultured rounded-md mx-1 cursor-pointer" /*onClick={() => reactSelect.focus()}*/>
        {/* <span className="pr-2">7 days</span>
        <FontAwesomeIcon icon={faAngleDown} /> */}

        <Select<DayOption, false, GroupedOption>
          defaultValue={defaultValue}
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
          classNamePrefix="react-select"
          openMenuOnFocus={true}
          isSearchable={false}
          onChange={(newValue: OnChangeValue<DayOption, false>, _actionMeta: ActionMeta<DayOption>) => {
            let newDuration = newValue?.value ?? DEFAULT_DURATION;
            setDuration(newDuration);
            props.onChange(startDate, newDuration);
          }}
          // ref={setReactSelect}
        />
      </div>
    </div>
  );
};

export default CustomCalendar;