import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import FeaturedDeal, { Deal } from './FeaturedDeal';
import API_URI from './utils';




type DealsResponse = {
  deals: Deal[];
}

function FeaturedDealPulse() {
  return (
    <div className="mx-auto animate-pulse">
      <div className="rounded-md w-full bg-slate-200 h-44"></div>
      <div className="px-2 -mt-4">
        <div className="w-full shadow-lg rounded-md p-4 text-left">
          <h3 className="text-2xl leading-tight font-medium mt-4">
            <span className="bg-slate-200 text-slate-200">Loading</span>
            <span className="relative text-2xl mx-3 ">
              <span className="block absolute -inset-1 bg-slate-400" aria-hidden="true"></span>
              <span className="relative text-slate-400 text-2xl whitespace-nowrap ">xxxx-day</span>
            </span>
            <p className="inline-block bg-slate-200 text-slate-200 mt-4">holiday package.</p>
          </h3>
          <div className="text-zinc-400 my-4">
            <p className="bg-slate-200 text-slate-200 mb-1">1-14 Sep 2022</p>
            <p className="bg-slate-200 text-slate-200 mb-1">All Stars resort</p>
            <p className="bg-slate-200 text-slate-200">Flying from London Heathrow</p>
          </div>
          <div className="flex items-center">
            <span className="text-xl font-bold bg-slate-200 text-slate-200">£xxxx</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function FeaturedDeals() {
  const [response, setResponse] = useState<DealsResponse | null>();
  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetch(API_URI + "/deals")
      .then(res => res.json())
      .then(
        (resp) => {
          setIsLoaded(true);
          setResponse(resp);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const featured = isLoaded ? response?.deals.map(deal => {
    return (
      <FeaturedDeal deal={deal} />
    )
  }) : (
    <>
      <FeaturedDealPulse />
      <FeaturedDealPulse />
      <FeaturedDealPulse />
      <FeaturedDealPulse />
    </>
  );

  const errorMsg = error ? (
    <>
      <div className="flex justify-center m-8">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-9xl text-violet" />
      </div>
      <h1 className="text-xl text-center">Our mice had some trouble</h1>
      <p className="text-sm text-center">The error we experienced is '{error.message}'. This may be a temporary problem, refresh your page to retry loading the list of cheapest holidays.</p>
    </>
  ) : null;

  return (
    <>
      <section className="lg:px-48 xl:px-64">
        <h2 className="text-4xl text-center m-4">Cheapest All-Inclusive Holidays</h2>
        {errorMsg}
        <div className="grid grid-cols lg:grid-cols-2 gap-20 auto-rows-fr">
          {featured}
        </div>
      </section>
    </>
  );
}

export default FeaturedDeals;