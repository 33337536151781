const API_URI = process.env.REACT_APP_MOUSETRACK_FRONTEND_API_ENDPOINT;

export function getAirportTitle(airport: string): string {
  switch (airport) {
    case "AnyLondon":
      return "London (Any Airport)";
    case "LondonHeathrow":
      return "London Heathrow";
    case "LondonGatwick":
      return "London Gatwick"
    case "LondonStansted":
      return "London Stansted";
    case "Orlando":
      return "Orlando International Airport"
    case "MelbourneOrlando":
      return "Melbourne Orlando International"
    default:
      return airport;
  }
}

export function getAirportShortCode(airport: string): string {
  switch (airport) {
    case "AnyLondon":
      return "LON";
    case "LondonHeathrow":
      return "LHR";
    case "LondonGatwick":
      return "LGW"
    case "LondonStansted":
      return "STN";
    case "Orlando":
      return "MCO"
    case "MelbourneOrlando":
      return "MLB"
    default:
      return airport;
  }
}

export function getHotelTitle(hotel: string): string {
  switch (hotel) {
    case "PopCentury": return "Disney's Pop Century Resort";
    case "CaribbeanBeach": return "Disney's Caribbean Beach Resort";
    case "AnimalKingdomLodge": return "Disney's Animal Kingdom Lodge";
    case "SaratogaSprings": return "Disney's Saratoga Springs Resort & Spa";
    case "PortOrleansRiverside": return "Disney's Port Orleans Resort - Riverside";
    case "AllStarMusic": return "Disney's All-Star Music Resort";
    case "AllStarMovies": return "Disney's All-Star Movies Resort";
    case "CoronadoSprings": return "Disney's Coronado Springs Resort";
    case "PortOrleansFrenchQuarter": return "Disney's Port Orleans Resort - French Quarter";
    case "AllStarSports": return "Disney's All-Star Sports Resort";
    case "ArtOfAnimation": return "Disney's Art of Animation Resort";
    case "PolynesianVillage": return "Disney's Polynesian Village Resort";
    case "GrandFloridian": return "Disney's Grand Floridian Resort & Spa";
    case "WildernessLodge": return "Disney's Wilderness Lodge";
    case "BeachClub": return "Disney's Beach Club Resort";
    case "ContemporaryResort": return "Disney's Contemporary Resort";
    case "YachtClub": return "Disney's Yacht Club Resort";
    case "BoardWalkInn": return "Disney's BoardWalk Inn";
    case "OldKeyWest": return "Disney's Old Key West Resort";
    case "PolynesianVillasBungalows": return "Disney's Polynesian Villas & Bungalows";
    case "ContemporaryResortBayLakeTower": return "Bay Lake Tower at Disney's Contemporary Resort";
    case "GrandFloridianVillas": return "The Villas at Disney's Grand Floridian Resort & Spa";
    case "AnimalKingdomKidaniVillage": return "Disney's Animal Kingdom Villas - Kidani Village";
    case "FortWildernessCabins": return "The Cabins at Disney's Fort Wilderness Resort";
    case "BoardWalkVillas": return "Disney's BoardWalk Villas";
    case "BeachClubVillas": return "Disney's Beach Club Villas";
    case "WildernessLodgeBoulderRidgeVillas": return "Boulder Ridge Villas at Disney's Wilderness Lodge";
    case "AnimalKingdomJamboHouse": return "Disney's Animal Kingdom Villas - Jambo House";
    case "FortWildernessCampsites": return "The Campsites at Disney's Fort Wilderness Resort";
    case "WildernessLodgeCopperCreekVillas": return "Copper Creek Villas & Cabins at Disney's Wilderness Lodge";
    case "Riviera": return "Disney's Riviera Resort";
    case "Dolphin": return "Walt Disney World Dolphin Hotel";
    case "Swan": return "Walt Disney World Swan Hotel";
    case "SwanReserve": return "Walt Disney World Swan Reserve";
    default:
      return hotel;
  }
}

export function getShortHotelTitle(hotel: string): string {
  switch (hotel) {
    case "PopCentury": return "Pop Century";
    case "CaribbeanBeach": return "Caribbean Beach";
    case "AnimalKingdomLodge": return "Animal Kingdom Lodge";
    case "SaratogaSprings": return "Saratoga Springs";
    case "PortOrleansRiverside": return "Port Orleans - Riverside";
    case "AllStarMusic": return "All-Star Music";
    case "AllStarMovies": return "All-Star Movies";
    case "CoronadoSprings": return "Coronado Springs ";
    case "PortOrleansFrenchQuarter": return "Port Orleans - French Quarter";
    case "AllStarSports": return "All-Star Sports ";
    case "ArtOfAnimation": return "Art of Animation ";
    case "PolynesianVillage": return "Polynesian Village";
    case "GrandFloridian": return "Grand Floridian";
    case "WildernessLodge": return "Wilderness Lodge";
    case "BeachClub": return "Beach Club";
    case "ContemporaryResort": return "Contemporary";
    case "YachtClub": return "Yacht Club";
    case "BoardWalkInn": return "BoardWalk Inn";
    case "OldKeyWest": return "Old Key West";
    case "PolynesianVillasBungalows": return "Polynesian Villas & Bungalows";
    case "ContemporaryResortBayLakeTower": return "Bay Lake Tower at Contemporary";
    case "GrandFloridianVillas": return "The Villas at Grand Floridian";
    case "AnimalKingdomKidaniVillage": return "Animal Kingdom - Kidani Village";
    case "FortWildernessCabins": return "The Cabins at Fort Wilderness";
    case "BoardWalkVillas": return "BoardWalk Villas";
    case "BeachClubVillas": return "Beach Club Villas";
    case "WildernessLodgeBoulderRidgeVillas": return "Boulder Ridge Villas";
    case "AnimalKingdomJamboHouse": return "Animal Kingdom Villas - Jambo House";
    case "FortWildernessCampsites": return "The Campsites at Fort Wilderness";
    case "WildernessLodgeCopperCreekVillas": return "Copper Creek Villas & Cabins";
    case "Riviera": return "Riviera";
    case "Dolphin": return "Dolphin Hotel";
    case "Swan": return "Swan Hotel";
    case "SwanReserve": return "Swan Reserve";
    default:
      return hotel;
  }
}

export function renderPrice(price: number, currency?: string | undefined): string {
  // https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
  return price.toLocaleString("en-GB", {style: "currency", currency: currency ?? "GBP"});
}

export function renderPriceShort(price: number, currency?: string | undefined): string {
  // https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
  return price.toLocaleString("en-GB", {style: "currency", currency: currency ?? "GBP", maximumFractionDigits: 0});
}

export function parseDate(date: string): Date {
  // This is assuming that our date has the format of YYYY-mm-dd (or possibly YYYY-m-dd).
  let [year, month, day] = date.split("-");
  return new Date(Number(year), Number(month)-1, Number(day));
}

export default API_URI;