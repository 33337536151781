import FrontPage from './FrontPage';
import SearchPage from './SearchPage';
import HistoryPage from './HistoryPage';
import InflationPage from './InflationPage';

import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SearchSettings } from './SearchBox';
import HolidayPriceTracker from './HolidayPriceTracker';


function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FrontPage/>}/>
        <Route path="/search" element={<SearchPage/>}/>
        <Route path="/history/hotel" element={<HistoryPage/>}/>
        <Route path="/inflation" element={<InflationPage/>}/>
        <Route path="/holiday-price-tracker" element={<HolidayPriceTracker/>}/>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
